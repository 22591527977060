import "../styles";
import "bootstrap";

$("form :input").focus(function() {
  $("label[for='" + this.id + "']").addClass("focus");
}).blur(function() {
  $("label").removeClass("focus");
});

$(".alert").delay(6000).fadeOut(1000);
